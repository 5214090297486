export const orderStatusWording = {
  CONFIRMING: 'Confirming Your Order',
  FINDING_DRIVER: 'Preparing Your Order',
  EN_ROUTE_TO_PICK_UP: 'En Route to Pickup',
  ARRIVED_AT_PICKED: 'Arrived at Pickup',
  PICKED_UP: 'En Route to You',
  ARRIVED_DROPPED_OFF: 'Arriving Now',
  COMPLETED: 'Delivered',
  CANCELLED: 'Order Cancelled',
} as { [any: string]: string }
